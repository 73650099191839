import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import * as dateutil from '@appbase/util/date-helper'
import LeaveCode from '@hr/components/LeaveCode/LeaveCode.vue';


@Component({
    components:{LeaveCode}
})
export default class LeaveCodeList extends Vue {
  //
  // ────────────────────────────────────────────────── I ──────────
  //   :::::: P R O P S : :  :   :    :     :        :          :
  // ────────────────────────────────────────────────────────────
  //

  @Prop({ type: Array }) items!: Array<any>
}
