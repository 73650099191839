import Vue from 'vue';
import { Component, Prop, PropSync, Watch } from 'vue-property-decorator';
import DatePicker from '@appbase/components/DatePicker/DatePicker.vue'
import api from '@hr/api';
import * as dateutil from '@appbase/util/date-helper'
import { mapGetters } from 'vuex';
import * as accountTypes from '@account/store/types';
import * as popupHelper from '@appbase/util/popup-helper';
import { createEventBus, dispatchEventBus, listenEvent, removeEventBus } from '@appbase/util/eventBus';

@Component({  components:{DatePicker}
    ,computed: mapGetters({
    user: accountTypes.CURRENT
    }),
})
export default class LeaveCodeHeader extends Vue {

    user!: account.TCurrent
    //
    // ────────────────────────────────────────────────── I ──────────
    //   :::::: P R O P S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────
    //

    @Prop({ type: Number }) pageNum!: number;
    @Prop({ type: Number }) rowSize!: number;

    //
    // ──────────────────────────────────────────────── I ──────────
    //   :::::: D A T A : :  :   :    :     :        :          :
    // ──────────────────────────────────────────────────────────
    //
    
    value: string = '';
    
 
    //
    // ────────────────────────────────────────────────────── I ──────────
    //   :::::: M E T H O D S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────
    //
    openAddPopup() {
        let _this = this;
        popupHelper.openPopup(`leavecodeAdd`, "/leavecodePopup/add",
            {
            },
            {
                width: 450,
                height: 650,
                isFrame: this.isTeams,
            },
            ()=>{ dispatchEventBus(window, 'refreshLeaveCodeList', '') }
        )
    }

    search() {
        var data = {
            pageNum: 1,
            rowSize: 20,
            comCode: this.user.company.comCode
        }
        this.$emit('load', data);
    }

    

    created(){
        let _this = this;
        createEventBus(window, 'refreshLeaveCodeList', _this.search);
    }

    beforeDestroy(){
        let _this = this;
        removeEventBus(window, 'refreshLeaveCodeList', _this.search);
    }
}