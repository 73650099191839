import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import * as dateutil from '@appbase/util/date-helper'
import * as popupHelper from '@appbase/util/popup-helper';
import { createEventBus, dispatchEventBus } from '../../../@appbase/util/eventBus';

@Component
export default class LeaveCode extends Vue {
  //
  // ────────────────────────────────────────────────── I ──────────
  //   :::::: P R O P S : :  :   :    :     :        :          :
  // ────────────────────────────────────────────────────────────
  //

  @Prop({ type: Object }) item!: any;

  //
  // ──────────────────────────────────────────────── I ──────────
  //   :::::: D A T A : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────
  //
  
  get deductClass() {
    return {
      'badge': true,
      'flex-shrink-0':true,
      'badge-red': this.item.isDeduct==false,
      'badge-green': this.item.isDeduct==true
    }
  }

  get usingClass() {
    return {
      'badge': true,
      'flex-shrink-0':true,
      'badge-red': this.item.isUsing==false,
      'badge-green': this.item.isUsing==true
    }
  }

  get LeaveDayClass() {
    return {
      'badge': true,
      'flex-shrink-0':true,
      'badge-red': this.item.usableLeaveDay==false,
      'badge-green': this.item.usableLeaveDay==true
    }
  }

  get LeaveHalfDayClass() {
    return {
      'badge': true,
      'flex-shrink-0':true,
      'badge-red': this.item.usableLeaveHalfDay==false,
      'badge-green': this.item.usableLeaveHalfDay==true
    }
  }

  get LeaveQuarterDayClass() {
    return {
      'badge': true,
      'flex-shrink-0':true,
      'badge-red': this.item.usableLeaveQuarterDay==false,
      'badge-green': this.item.usableLeaveQuarterDay==true
    }
  }
  //
  // ────────────────────────────────────────────────────── I ──────────
  //   :::::: M E T H O D S : :  :   :    :     :        :          :
  // ────────────────────────────────────────────────────────────────
  //

  boolDisplay(b:boolean){
    if(b){
      return 'Yes'
    }

    return 'No'
  }
  
  callRefresh(){
    dispatchEventBus(window, 'refreshLeaveCodeList', '');
    
  }

  openModifyPopup(){
    let _this = this;
    popupHelper.openPopup(`leavecodeModify_${this.item.id}`, "/leavecodePopup/modify",
      {
          id: this.item.id,
      },
      {
          width: 450,
          height: 650,
          isFrame: this.isTeams
      },
      _this.callRefresh
    )

  }

  //
  // ────────────────────────────────────────────────── I ──────────
  //   :::::: W A T C H : :  :   :    :     :        :          :
  // ────────────────────────────────────────────────────────────
  //
  
    @Watch('item')
    onChangedItem(){
        //this.createdDate = this.item.createdDate.split(' ')[0];
        // this.createdDate = dateutil.uGetDateFormat('yyyy-MM-DD', dateutil.IEDate(this.item.createdDate));
        // this.browser = this.item.app.split('.')[0];
    }

 

   created(){
     this.onChangedItem();
   }
}
