import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import LeaveCodeContainer from '@hr/containers/LeaveCodeContainer.vue'
@Component({
    components:{
        LeaveCodeContainer
    }
})
export default class LeaveCodeView extends Vue {
   
}
