import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import LeaveCodeHeader from '@hr/components/LeaveCode/LeaveCodeHeader.vue';
import LeaveCodeList from '@hr/components/LeaveCode/LeaveCodeList.vue';
import PageNavigationBar from '@appbase/components/Paginate/PageNavigationBar.vue';
import api from '@hr/api';
import * as dateutil from '@appbase/util/date-helper'
import { mapGetters } from 'vuex';
import * as accountTypes from '@account/store/types';


@Component ({
    components: { LeaveCodeHeader, LeaveCodeList, PageNavigationBar },
    computed: mapGetters({
        user: accountTypes.CURRENT
    }),
})
export default class LeaveCodeContainer extends Vue {
    user!: account.TCurrent
    //
    // ──────────────────────────────────────────────────────── I ──────────
    //   :::::: D A T A : :  :   :    :     :        :          :
    // ──────────────────────────────────────────────────────────────────
    //

    items: Array<any> = [];
    totalCount: number = 0;
    pageNum: number = 1;
    rowSize: number = 20;

    // 휴가 구분(연차, 연공, 유급 등등)
    leaveCategoryList: Array<string> = [];
    // 계열사 (C021, C04 등)
    comGroupCodeList: Array<Leave.comGroup> = [];

    //
    // ──────────────────────────────────────────────────────── I ──────────
    //   :::::: M E T H O D : :  :   :    :     :        :          :
    // ──────────────────────────────────────────────────────────────────
    //

    load(data: any) {
        const {pageNum, rowSize, comCode} = data;

        // 계열사 리스트, 휴가 구분 리스트
        api.leave.getLeaveCodeList(pageNum, rowSize, comCode).then((res: any) => {
            if (res) {
                this.items = res.items;
                this.totalCount = res.totalCount;
                this.leaveCategoryList = res.leaveCategoryList;
                this.comGroupCodeList = res.comGroupCodeList;

                // Modal에 전달하기 위해 localStorage에서 휴가구분, 계열사 리스트 저장하기
                localStorage.setItem('leaveCategoryList', JSON.stringify(this.leaveCategoryList));
                localStorage.setItem('comGroupCodeList', JSON.stringify(this.comGroupCodeList));
            }
        });

        
  
    }

    pageMove(number: number) {
        this.pageNum = number;

        var data = {
            pageNum: this.pageNum,
            rowSize: this.rowSize,
            comCode: this.user.company.comCode
        }
        this.load(data);
    }


    //
    // ──────────────────────────────────────────────────────── I ──────────
    //   :::::: L I F E C Y L E : :  :   :    :     :        :          :
    // ──────────────────────────────────────────────────────────────────
    //

    mounted() {

    }

    created() {
        this.setPage({title:this.$t('휴가종류관리')});
        var data = {
            pageNum: this.pageNum,
            rowSize: this.rowSize,
            comCode: this.user.company.comCode
        }
        this.load(data);
    }

    destroyed() {
        // Vue Component가 destoryed 될 때 localstorage 삭제
        localStorage.removeItem('leaveCategoryList');
        localStorage.removeItem('comGroupCodeList');
    }
}
